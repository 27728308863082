<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="items"
    :mobile-breakpoint="0"
    :page="pagination.current_page"
    :server-items-length="pagination.total"
    :loading="loading"
    :item-class="() => 'clickable'"
    :class="{ 'content-loading': loading }"
    disable-sort
    @update:page="tableMixin_changePage"
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.supplier="{ item }">
      <template v-if="item.supplier">{{ item.supplier.name || item.supplier.full_name }}</template>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.supplier>
          <template v-if="item.supplier">{{
            item.supplier.name || item.supplier.full_name
          }}</template>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';

export default {
  name: 'ProductTable',

  components: { BaseExpandedTableRow, BaseActionMenu },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('general.title'),
          value: 'name',
        },
        {
          text: this.$t('general.price'),
          value: 'price',
        },
        {
          text: this.$t('general.supplier'),
          value: 'supplier',
        },
        { value: 'actions' },
      ];
    },
  },

  methods: {
    getRowActions() {
      const actions = [];
      if (this.tableMixin_hiddenHeaders.length) {
        actions.push({
          callback: (p) => this.$emit('click:row', p),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
          inMenu: this.$vuetify.breakpoint.xsOnly,
        });
      }
      actions.push({
        callback: (p) => this.$emit('delete', p),
        label: this.$t('general.controls.delete'),
        icon: 'delete',
        inMenu: this.$vuetify.breakpoint.xsOnly,
      });
      return actions;
    },
  },
};
</script>

<style scoped></style>
