var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.per_page],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.items,"mobile-breakpoint":0,"page":_vm.pagination.current_page,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"item-class":function () { return 'clickable'; },"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.supplier",fn:function(ref){
  var item = ref.item;
return [(item.supplier)?[_vm._v(_vm._s(item.supplier.name || item.supplier.full_name))]:_vm._e()]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.supplier",fn:function(){return [(item.supplier)?[_vm._v(_vm._s(item.supplier.name || item.supplier.full_name))]:_vm._e()]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }