<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchProducts(productFilterParams)">
        {{ $t('general.products.products') }}
      </v-tab>

      <v-tab-item>
        <!--        <div class="d-flex align-center justify-end px-4">-->
        <!--          <ProductFilter />-->
        <!--        </div>-->

        <ProductTable
          :items="products"
          :loading="$store.getters.loading['get:api/products']"
          :pagination="productPagination"
          @click:row="$router.push({ name: 'editProduct', params: { productId: $event.id } })"
          @delete="deleteProduct"
          @update:page="onPageChange"
        />
      </v-tab-item>
    </v-tabs>

    <router-view />

    <BasePrimaryActionButton @click="$router.push({ name: 'createProduct' })" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ProductTable from '@/components/tables/ProductTable';
import { getSanitizedFilterParams } from '@/util/filter-params';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';

export default {
  name: 'Products',

  components: { BasePrimaryActionButton, ProductTable },

  computed: {
    ...mapState('products', ['products', 'productPagination', 'productFilterParams']),
  },

  created() {
    this.fetchProducts(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.productFilterParams) !== JSON.stringify(params) &&
      to.name === 'products'
    ) {
      this.fetchProducts(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('products', ['fetchProducts', 'deleteProduct']),

    onPageChange(page) {
      const query = { ...this.productFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'products', query });
    },
  },
};
</script>

<style scoped></style>
